import React, { useEffect, useState } from "react"
import cx from "classnames"
import Img from "gatsby-image/withIEPolyfill"
import { useStaticQuery, graphql, Link } from "gatsby"
import Container from "components/Container"
import { Logo, LogoPPC, Burger, X, ArrowDown, ArrowSubMenu, SingleArrowRight } from "components/Icons"
import styles from "./Header.module.css"

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    })

    useEffect(() => {
        if (typeof window !== "undefined") {
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight
                })
            }
            window.addEventListener("resize", handleResize, {passive: true})
            handleResize()
            return () => window.removeEventListener("resize", handleResize)
        }
    }, [])
    return windowSize
};

export const Nav = ({ label, links }) => {
  const [isOpen, onToggle] = useState(false)
  const onMouseEnter = () => (window.innerWidth > 1025 ? onToggle(true) : null)
  const onMouseLeave = () => (window.innerWidth > 1025 ? onToggle(false) : null)
  const onClick = () => (window.innerWidth <= 1025 ? onToggle(!isOpen) : null)

  useEffect(() => {
    window.addEventListener("mouseleave", onMouseLeave, {passive: true})
    return () => window.removeEventListener("mouseleave", onMouseLeave)
  })

  return (
    <div
      tabIndex="0"
      role="button"
      className={cx(styles.item, { [styles.active]: isOpen })}
      onClick={onClick}
      onKeyDown={() => {}}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span>
        {label} {ArrowDown}
      </span>
      {isOpen ? (
        <div>
          <div>
            <ul>
              {links.map((link) => (
                <li key={link.path}>
                  <Link to={link.path}>{link.label}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export const NavWhatWeDo = ({ label, links, buttonLink, buttonLabel }) => {
  const [isOpen, onToggle] = useState(false)
  const onMouseEnter = () => (window.innerWidth > 1025 ? onToggle(true) : null)
  const onMouseLeave = () => (window.innerWidth > 1025 ? onToggle(false) : null)
  const onClick = () => (window.innerWidth <= 1025 ? onToggle(!isOpen) : null)

  useEffect(() => {
    window.addEventListener("mouseleave", onMouseLeave, {passive: true})
    return () => window.removeEventListener("mouseleave", onMouseLeave)
  })

  return (
    <div
      tabIndex="0"
      role="button"
      className={cx(styles.item, { [styles.active]: isOpen })}
      onClick={onClick}
      onKeyDown={() => {}}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span>
        {label} {ArrowSubMenu} <span>{ArrowDown}</span>
      </span>
      <div className={styles.submenu}>
        <div className={styles.submenuWhatWeDo}>
          <div className={styles.submenuWrap}>
          {links.map((group) => (
            <ul>
              <li>{group.heading}</li>
              {group.data.map((item) => (
                <li key={item.node.frontmatter.url}>
                  <Link to={`/${item.node.frontmatter.url}`}>
                    <span>{item.node.frontmatter.navTitle ? item.node.frontmatter.navTitle : item.node.frontmatter.title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          ))}
          </div>
          {buttonLink ? (
            <Link to={buttonLink}>
              <span>{buttonLabel}</span>
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export const NavPartners = ({ label, title, subtitle, links }) => {
  const [isOpen, onToggle] = useState(false)
  const onMouseEnter = () => (window.innerWidth > 1025 ? onToggle(true) : null)
  const onMouseLeave = () => (window.innerWidth > 1025 ? onToggle(false) : null)
  const onClick = () => (window.innerWidth <= 1025 ? onToggle(!isOpen) : null)

  useEffect(() => {
    window.addEventListener("mouseleave", onMouseLeave, {passive: true})
    return () => window.removeEventListener("mouseleave", onMouseLeave)
  })

  const windowSize = useWindowSize()

  return (
    <div
      tabIndex="0"
      role="button"
      className={cx(styles.item, { [styles.active]: isOpen })}
      onClick={onClick}
      onKeyDown={() => {}}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span>
        {label} {ArrowSubMenu} <span>{ArrowDown}</span>
      </span>
      <div className={styles.submenu}>
        <div className={styles.submenuPartners}>
          {windowSize.width > 1025 ? (
            <>
              <div className={styles.submenuFeature}>
                <p>
                  <span>{subtitle}</span>
                  {title}
                </p>
              </div>
              <div className={cx(styles.submenuWrap, styles.submenuPartnersWrap)}>
                {links.map((item) => (
                  <ul>
                    <li>
                      <Link to={`/${item.node.frontmatter.url}`}>
                        <img
                          objectFit="fit"
                          alt={item.node.frontmatter.title}
                          title={item.node.frontmatter.title}
                          src={item.node.frontmatter.menuImage.publicURL}
                        />
                      </Link>
                    </li>
                    <li key={item.node.frontmatter.url}>
                      <Link to={`/${item.node.frontmatter.url}`}>
                        <span>{item.node.frontmatter.menuButtonLabel}</span>
                        {SingleArrowRight}
                      </Link>
                    </li>
                  </ul>
                ))}
              </div>
            </>
          ) : (
            <div className={styles.submenuWrap}>
              {links.map((item) => (
                <ul>
                  <li key={item.node.frontmatter.url}>
                    <Link to={`/${item.node.frontmatter.url}`}>
                      <span>{item.node.frontmatter.navTitle ? item.node.frontmatter.navTitle : item.node.frontmatter.title}</span>
                    </Link>
                  </li>
                </ul>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const NavIndustries = ({ label, title, subtitle, links }) => {
  const [isOpen, onToggle] = useState(false)
  const onMouseEnter = () => (window.innerWidth > 1025 ? onToggle(true) : null)
  const onMouseLeave = () => (window.innerWidth > 1025 ? onToggle(false) : null)
  const onClick = () => (window.innerWidth <= 1025 ? onToggle(!isOpen) : null)

  useEffect(() => {
    window.addEventListener("mouseleave", onMouseLeave, {passive: true})
    return () => window.removeEventListener("mouseleave", onMouseLeave)
  })

  return (
    <div
      tabIndex="0"
      role="button"
      className={cx(styles.item, { [styles.active]: isOpen })}
      onClick={onClick}
      onKeyDown={() => {}}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span>
        {label} {ArrowSubMenu} <span>{ArrowDown}</span>
      </span>
      <div className={styles.submenu}>
        <div className={styles.submenuPartners}>
          <div className={styles.submenuFeature}>
            <p>
              <span>{subtitle}</span>
              {title}
            </p>
          </div>
          <div className={cx(styles.submenuWrap, styles.submenuIndustriesWrap)}>
            {links.map((group) => (
              <ul>
                {group.data.map((item) => (
                  <li key={item.node.frontmatter.url}>
                    <Link to={`/${item.node.frontmatter.url}`}>
                      <span>{item.node.frontmatter.navTitle ? item.node.frontmatter.navTitle : item.node.frontmatter.title}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export const NavSuccessStories = ({ label, title, subtitle, titleLink, titleLinkLabel, heading, buttonLink, buttonLabel, customers }) => {
  const [isOpen, onToggle] = useState(false)
  const onMouseEnter = () => (window.innerWidth > 1025 ? onToggle(true) : null)
  const onMouseLeave = () => (window.innerWidth > 1025 ? onToggle(false) : null)
  const onClick = () => (window.innerWidth <= 1025 ? onToggle(!isOpen) : null)

  useEffect(() => {
    window.addEventListener("mouseleave", onMouseLeave, {passive: true})
    return () => window.removeEventListener("mouseleave", onMouseLeave)
  })

  const windowSize = useWindowSize()

  return (
    <div
      tabIndex="0"
      role="button"
      className={cx(styles.item, { [styles.active]: isOpen })}
      onClick={onClick}
      onKeyDown={() => {}}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span>
        {label} {ArrowSubMenu} <span>{ArrowDown}</span>
      </span>
      <div className={styles.submenu}>
        {windowSize.width > 1025 ? (
          <div className={styles.submenuPartners}>
            <div className={styles.submenuFeature}>
              <p>
                <span>{subtitle}</span>
                {title}
                <Link to={titleLink}>
                  <span>{titleLinkLabel}</span>
                  {SingleArrowRight}
                </Link>
              </p>
            </div>
            <div className={cx(styles.submenuWrap, styles.submenuSuccessStoriesWrap, styles.submenuInsightsWrap)}>
              <p>
                <span></span>
                <Link to={buttonLink}>
                  <span>{buttonLabel} {SingleArrowRight}</span>
                </Link>
              </p>
              {(customers || []).filter(Boolean).map(({ node }) => (
                <div className={styles.customersitem} key={node.fields.slug}>
                  <Link to={`/${node.frontmatter.url}`} className={styles.customersitemwrap}>
                    <div className={styles.customersdetails}>
                      <div className={styles.customerslogo}>
                        <Img
                          width="106"
                          objectFit="contain"
                          alt={node.frontmatter.title}
                          title={node.frontmatter.title}
                          fluid={node.frontmatter.logo.childImageSharp.fluid}
                        />
                      </div>
                      {node.frontmatter.mainTitle ? (
                        <p className={styles.customertitle}>
                          {node.frontmatter.mainTitle}
                        </p>
                      ) : null}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className={styles.submenuWhatWeDo}>
            <div className={styles.submenuWrap}>
              <ul>
                <li>{subtitle}</li>
              </ul>
              {(customers || []).filter(Boolean).map(({ node }) => (
                <ul>
                  <li key={node.fields.slug}>
                    <Link to={`/${node.frontmatter.url}`}>
                      <span>{node.frontmatter.mainTitle}</span>
                    </Link>
                  </li>
                </ul>
              ))}
            </div>
            <Link to={buttonLink}>
              <span>Learn More {SingleArrowRight}</span>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export const NavInsights = ({ label, insights, resources }) => {
  const [isOpen, onToggle] = useState(false)
  const onMouseEnter = () => (window.innerWidth > 1025 ? onToggle(true) : null)
  const onMouseLeave = () => (window.innerWidth > 1025 ? onToggle(false) : null)
  const onClick = () => (window.innerWidth <= 1025 ? onToggle(!isOpen) : null)

  useEffect(() => {
    window.addEventListener("mouseleave", onMouseLeave, {passive: true})
    return () => window.removeEventListener("mouseleave", onMouseLeave)
  })

  const windowSize = useWindowSize()

  return (
    <div
      tabIndex="0"
      role="button"
      className={cx(styles.item, { [styles.active]: isOpen })}
      onClick={onClick}
      onKeyDown={() => {}}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span>
        {label} {ArrowSubMenu} <span>{ArrowDown}</span>
      </span>
      <div className={styles.submenu}>
        <div className={styles.submenuInsights}>
          <div className={styles.submenuInsightsMain}>
            {windowSize.width > 1025 ? (
              <div className={cx(styles.submenuWrap, styles.submenuInsightsResources)}>
                {resources.map((insight) => (
                  <div className={styles.submenuInsightsResourcesItem} key={insight.data.node.fields.slug}>
                    <div className={styles.submenuInsightsResourcesItemWrap}>
                      <p>
                        <Link to={insight.buttonLink}>{insight.heading} 
                          <span>{insight.subHeading}</span>
                        </Link>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {insights.map((insight) => 
              windowSize.width > 1025 ? (
                <div className={cx(styles.submenuWrap, styles.submenuInsightsWrap)} key={insight.heading}>
                  <p>
                    <span>{insight.heading}</span>
                    <Link to={insight.buttonLink}>
                      <span>{insight.buttonLabel} {SingleArrowRight}</span>
                    </Link>
                  </p>
                  {insight.data.map((post) => (
                    <div className={styles.submenuInsightsItem} key={post.node.fields.slug}>
                      <div className={styles.submenuInsightsItemWrap}>
                        <Link to={`/${insight.pathExt}${post.node.frontmatter.url}`} className={styles.submenuInsightsDetails}>
                          <div className={styles.submenuInsightsImage}>
                            {post.node.frontmatter.image.childImageSharp ? (
                              <Img
                                width="458"
                                objectFit="fill"
                                alt={post.node.frontmatter.title}
                                title={post.node.frontmatter.title}
                                fluid={post.node.frontmatter.image.childImageSharp.fluid}
                              />
                            ) : (
                              <Img
                                width="458"
                                objectFit="fill"
                                alt={post.node.frontmatter.title}
                                title={post.node.frontmatter.title}
                                src={post.node.frontmatter.image}
                              />
                            )}
                          </div>
                          {post.node.frontmatter.title ? (
                            <p>{post.node.frontmatter.title}</p>
                          ) : null}
                        </Link>
                      </div>
                    </div>
                ))}
              </div>
              ) : (
                <div className={styles.submenuWrap}>
                  {resources.map((insight) => (
                    <ul className={styles.submenuList}>
                      <li key={insight.data.node.fields.slug}>
                        <Link to={insight.buttonLink}>{insight.heading} 
                          <span>{insight.subHeading}</span>
                        </Link>
                      </li>
                    </ul>
                  ))}
                  <ul>
                    <li>{insight.heading}</li>
                  </ul>
                  {insight.data.map((post) => (
                    <ul>
                      <li key={post.node.fields.slug}>
                        <Link to={`/${insight.pathExt}${post.node.frontmatter.url}`}>
                          <span>{post.node.frontmatter.title}</span>
                        </Link>
                      </li>
                    </ul>
                  ))}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export const NavAboutUs = ({ label, title, subtitle, titleLink, titleLinkLabel, links }) => {
  const [isOpen, onToggle] = useState(false)
  const onMouseEnter = () => (window.innerWidth > 1025 ? onToggle(true) : null)
  const onMouseLeave = () => (window.innerWidth > 1025 ? onToggle(false) : null)
  const onClick = () => (window.innerWidth <= 1025 ? onToggle(!isOpen) : null)

  useEffect(() => {
    window.addEventListener("mouseleave", onMouseLeave, {passive: true})
    return () => window.removeEventListener("mouseleave", onMouseLeave)
  })

  const windowSize = useWindowSize()

  return (
    <div
      tabIndex="0"
      role="button"
      className={cx(styles.item, { [styles.active]: isOpen })}
      onClick={onClick}
      onKeyDown={() => {}}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span>
        {label} {ArrowSubMenu} <span>{ArrowDown}</span>
      </span>
      <div className={styles.submenu}>
        <div className={styles.submenuPartners}>
          {windowSize.width > 1025 ? (
            <>
              <div className={styles.submenuFeature}>
                <p>
                  <span>{subtitle}</span>
                  {title}
                  <Link to={titleLink}>
                    <span>{titleLinkLabel}</span>
                    {SingleArrowRight}
                  </Link>
                </p>
              </div>
              <div className={cx(styles.submenuWrap, styles.submenuAboutUsWrap)}>
                {links.map((link) => (
                  <div key={link.path} className={styles.submenuAboutUsItem}>
                    <Link to={link.path} className={styles.submenuAboutUsItemWrap}>
                      <Img
                        objectFit="fit"
                        alt={link.title}
                        title={link.title}
                        fluid={link.image.childImageSharp.fluid}
                      />
                      <p>{link.title}</p>
                    </Link>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className={styles.submenuWrap}>
              {links.map((link) => (
                <ul>
                  <li key={link.path}>
                    <Link to={link.path}>
                      <span>{link.title}</span>
                    </Link>
                  </li>
                </ul>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const Insights = ({ label, path, insights }) => {
  const [isOpen, onToggle] = useState(false)
  const onMouseEnter = () => (window.innerWidth > 1025 ? onToggle(true) : null)
  const onMouseLeave = () => (window.innerWidth > 1025 ? onToggle(false) : null)
  const onClick = () => (window.innerWidth <= 1025 ? onToggle(!isOpen) : null)

  useEffect(() => {
    window.addEventListener("mouseleave", onMouseLeave, {passive: true})
    return () => window.removeEventListener("mouseleave", onMouseLeave)
  })

  return (
    <div
      tabIndex="0"
      role="button"
      className={cx(styles.item, { [styles.active]: isOpen })}
      onClick={onClick}
      onKeyDown={() => {}}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span>
        {label} {ArrowDown}
      </span>
      {isOpen ? (
        <div>
          <div>
            <ul>
              {[
                { path: "/blog", label: "Blog" },
                // { path: "/whitepapers", label: "Whitepapers" },
                { path: "/resources", label: "Resources" },
              ].map((link) => (
                <li key={link.path}>
                  {link.path.startsWith("http") ? (
                    <a href={link.path} rel="noopener noreferrer" target="_blank">
                      {link.label}
                    </a>
                  ) : (
                    <Link to={link.path}>{link.label}</Link>
                  )}
                </li>
              ))}
              <li key="Case Studies" className={styles.casestudy}>
                <Link to="/success-stories">Case Studies</Link>
                <div>
                  <div>
                    {insights.map((i) => (
                      <Link to={i.path} key={i.path}>
                        {i.label}
                      </Link>
                    ))}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export const Header = ({ location, data, partners, isLight, layout }) => {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false)

  useEffect(() => {
    document.body.style.overflow = isNavigationOpen ? "hidden" : ""
  }, [isNavigationOpen])

  return (
    <>
      {/* Just leaving this here in case we want to highlight future events
      {location.pathname === '/' ? (
        <div className={styles.kdAwsBannerContainer}>
          <Link to="/post/aws-canadian-partner-of-the-year-award-2023">
            <div className={styles.kdAwsBannerInner}>
              <span><u>Announcement</u>:</span>
              <span>2023 AWS Canadian Partner of the Year</span>
              <img
                width="200"
                height="28"
                loading="eager"
                objectFit="contain"
                alt="AWS Partner Logo"
                title="AWS Partner Logo"
                src={`/img/logo-opsguru-aws-partner.svg`}
              />
            </div>
          </Link>
        </div>
      ) : null} */}
      {layout === 'ppc' || layout === 'ppcformbanner' ? (
        <header className={cx(styles.rootwrap, styles.rootwrapPPC)}>
          <Container
            className={cx(styles.rootPPC, styles.root, { [styles.light]: isLight, [styles.navopen]: isNavigationOpen })}
          >
            <Link className={cx(styles.logo, styles.logoPPC)} to="/">
              OpsGuru
              {LogoPPC}
            </Link>
              <div className={styles.ppcHeaderContact}>
                {location.pathname.replace(/\/$/, "") === '/google-cloud-israel' ? (
                  <a href="mailto:sales@opsguru.co.il" className={cx(styles.ppcPhone, styles.ppcPhoneEmail)}>
                    <span>sales@opsguru.co.il</span>
                  </a>
                ) : (
                  <a href="tel:+9726970097" className={styles.ppcPhone}>
                    <span>+972 6970097</span>
                  </a>
                )}
                {layout !== 'ppcformbanner' ? (
                  <Link to="#contact-form" className={cx(styles.contactus, styles.getStarted)}>
                    <span>Get Started</span>
                  </Link>
                ) : null}
              </div>
          </Container>
        </header>
      ) : (
      <header className={styles.rootwrap}>
        <div className={styles.headerTop}>
          <Container className={styles.root}>
            <div className={styles.headerTopWrap}>
              <Link to="/careers">Careers</Link>
              <Link className={styles.headerTopContact} to="/contact">Contact Us</Link>
            </div>
          </Container>
        </div>
        <div className={styles.headerMain}>
          <Container
            className={cx(styles.root, { [styles.light]: isLight, [styles.navopen]: isNavigationOpen })}
          >
            <Link className={styles.logo} to="/">
              OpsGuru
              {Logo}
            </Link>

            <button
              type="button"
              aria-label="Open the Menu"
              className={styles.burger}
              onClick={() => setIsNavigationOpen(!isNavigationOpen)}
            >
              {Burger}
              {X}
            </button>

            <nav className={styles.nav}>
              <NavWhatWeDo
                label="What We Do"
                links={[
                  { 
                    heading: "Getting Started",
                    data: data.gettingstarted.edges,
                  },
                  { 
                    heading: "Accelerating Success",
                    data: data.acceleratingsuccess.edges,
                  },
                  { 
                    heading: "Optimizing Productivity",
                    data: data.optimizingproductivity.edges,
                  },
                ]}
                gettingstarted={data.gettingstarted.edges}
              />

              <NavPartners
                label="Partners"
                subtitle="OPSGURU IS A MULTI-CLOUD EXPERT"
                title="100s of Successful Programs Across All Major Clouds"
                links={data.partners.edges}
              />

              <NavSuccessStories
                label="Success Stories"
                subtitle="Success Stories"
                title="We help our customers make their future happen!"
                titleLink="/success-stories"
                titleLinkLabel="View All"
                buttonLink="/success-stories"
                buttonLabel="View All Success Stories"
                customers={data.successstories.edges}
              />

              <NavInsights
                label="Insights"
                insights={[
                  { 
                    heading: "Latest Blog Posts",
                    pathExt: "post/",
                    buttonLink: "/blog",
                    buttonLabel: "View All Blogs",
                    data: data.blogPosts.edges
                  },
                ]}
                resources={[
                  {
                    heading: "Blog",
                    subHeading: "Explore our Latest Cloud Insights",
                    buttonLink: "/blog",
                    data: {
                      node: {
                        fields: {
                          slug: '/blog'
                        },
                      }
                    }
                  },
                  { 
                    heading: "Resources",
                    subHeading: "Gain Useful Insights from our Ebooks and Whitepapers",
                    pathExt: "resources/",
                    buttonLink: "/resources",
                    buttonLabel: "View All Resources",
                    data: data.resources.edges[0]
                  },
                  { 
                    heading: "Events",
                    subHeading: "Join Us at an Event Near You",
                    pathExt: "",
                    buttonLink: "/events",
                    buttonLabel: "View All Events",
                    data: {
                      node: {
                        fields: {
                          slug: '/event/join-opsguru'
                        },
                        frontmatter: {
                          id: "Join OpsGuru, Cologix, Inc. and Google Cloud for a virtual event on February 23rd",
                          url: "event/join-opsguru",
                          title: "Join OpsGuru, Cologix, Inc. and Google Cloud for a virtual event on February 23rd",
                          image: "/img/image-events-preview.jpg",
                        }
                      }
                    }
                  },
                ]}
              />
              <NavAboutUs
                label="About Us"
                subtitle="Company Overview"
                title="Canada's Leading Team of Multi-Cloud Experts"
                titleLink="/about-us"
                titleLinkLabel="Learn More"
                links={[
                  { 
                    path: '/about-us',
                    image: data.aboutusimage,
                    title: 'About Us',
                  },
                  {
                    path: '/team-page',
                    image: data.leadershipimage,
                    title: 'Leadership Team',
                  },
                  { 
                    path: '/locations',
                    image: data.locationsimage,
                    title: 'Locations',
                  },
                ]}
              />

            </nav>
          </Container>
        </div>
      </header>
      )}
    </>
  )
}

export const HeaderWrapper = (props) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      aboutusimage: file(relativePath: { eq: "menu-about-us.jpg" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 183, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      leadershipimage: file(relativePath: { eq: "menu-leadership.jpg" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 183, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      locationsimage: file(relativePath: { eq: "menu-locations.jpg" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 183, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      site {
        siteMetadata {
          title
          navigation {
            label
            path
            links {
              label
              path
            }
          }
        }
      }
      gettingstarted: allMarkdownRemark(
        sort: { fields: [frontmatter___menuOrder], order: ASC }
        filter: { 
          frontmatter: { 
            templateKey: { eq: "whatwedo" },
            category: { eq: "Getting Started" },
            menuOrder: { ne: null },
            url: { ne: "devOps-enablement" }
          } 
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              url
              title
              navTitle
            }
          }
        }
      }
      acceleratingsuccess: allMarkdownRemark(
        sort: { fields: [frontmatter___menuOrder], order: ASC }
        filter: { 
          frontmatter: { 
            templateKey: { eq: "whatwedo" },
            category: { eq: "Accelerating Success" },
            menuOrder: { ne: null },
            url: { ne: "aws-map" }
          } 
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              url
              title
              navTitle
            }
          }
        }
      }
      optimizingproductivity: allMarkdownRemark(
        sort: { fields: [frontmatter___menuOrder], order: ASC }
        filter: { 
          frontmatter: { 
            templateKey: { eq: "whatwedo" },
            category: { eq: "Optimizing Productivity" },
            menuOrder: { ne: null },
            url: { ne: "cloud-spend-optimization" }
          } 
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              url
              title
              navTitle
            }
          }
        }
      }
      partners: allMarkdownRemark(
        sort: { fields: [frontmatter___menuOrder], order: ASC }
        filter: { frontmatter: { templateKey: { eq: "partner" }, url: { ne: "aws-cloud-consulting" }, menuOrder: { ne: null } } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              url
              title
              navTitle
              menuImage {
                extension
                publicURL
                childImageSharp {
                  fluid(maxWidth: 472, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              menuButtonLabel
            }
          }
        }
      }
      industries: allMarkdownRemark(
        sort: { fields: [frontmatter___menuOrder], order: ASC }
        filter: { frontmatter: { templateKey: { eq: "industries" }, menuOrder: { ne: null } } }
        limit: 5
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              url
              title
              navTitle
            }
          }
        }
      }
      industries2: allMarkdownRemark(
        sort: { fields: [frontmatter___menuOrder], order: ASC }
        filter: { frontmatter: { templateKey: { eq: "industries" }, menuOrder: { ne: null } } }
        limit: 5
        skip: 5
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              url
              title
              navTitle
            }
          }
        }
      }
      industries3: allMarkdownRemark(
        sort: { fields: [frontmatter___menuOrder], order: ASC }
        filter: { frontmatter: { templateKey: { eq: "industries" }, menuOrder: { ne: null } } }
        limit: 5
        skip: 10
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              url
              title
              navTitle
            }
          }
        }
      }
      successstories: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { templateKey: { eq: "successstories" } } }
        limit: 3
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              id
              url
              mainTitle
              title
              logo {
                extension
                publicURL
                childImageSharp {
                  fluid(maxWidth: 204, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              image {
                extension
                publicURL
                childImageSharp {
                  fluid(maxWidth: 244, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
      blogPosts: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
          frontmatter: {
            templateKey: { eq: "blog" } 
          },
          isFuture: { eq: false }
        }
        limit: 2
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              id
              url
              title
              image {
                childImageSharp {
                  fluid(maxWidth: 472, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
      resources: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { templateKey: { eq: "resource" } } }
        limit: 1
      )   {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              id
              url
              title
              image {
                extension
                publicURL
                childImageSharp {
                  fluid(maxWidth: 472, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return <Header data={data} {...props} />
}

export default HeaderWrapper
